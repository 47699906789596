<script>
import Armory from "@mindsmiths/armory-sdk/src/Armory";
import Table from "./components/Table.vue";

export default {
  name: "App",
  extends: Armory,
  methods: {
    getCustomTemplates() {
      return {};
    },
    getCustomComponents() {
      return {
        Table
      };
    },
    // eslint-disable-next-line no-unused-vars
    preReceivedSpecificationTrigger(receivedData) {
    },
    postReceivedSpecificationTrigger() {
    }
  }
};
</script>
